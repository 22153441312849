import { mapGetters } from 'vuex';
import * as Encoding from 'encoding-japanese';
//helpers
import { checkFileSize, checkCsvFile, buildFormData } from '@/helpers/file';
//constants
import { FileConstants } from '@/constants/file';

export default {
  data: function() {
    return {}
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
    }),
    importPath() {
      return `${this.userInfo?.id}_${this.subdomain}`;
    },
  },
  methods: {
    uploadCsv() {
      this.$refs.csvInput.click();
    },
    removeCsvFile() {
      this.$refs.csvInput.value = null;
    },
    checkImportStatus(id, type = 'csv', resetCheckbox = false, isDownloadCsv = true, cancelType = null) {
      this.statusTimer = setTimeout(async () => {
        const result = await this.$store.dispatch(this.importStatusAction, id);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkImportStatus(id, type, resetCheckbox, isDownloadCsv, cancelType);
              break;
            case 1:
              this.$message.showSuccess(`import_${type}_finished_success`);
              this.$refs.pagination.resetPagination();
              if (isDownloadCsv && result.errorData) this.downloadCsv(result.errorData, result.filename);
              this.removeImportingCsv();
              if (this.$refs?.table || resetCheckbox) this.$refs.table.resetCheck();
              break;
            case 2:
              if (result.count) this.$refs.pagination.resetPagination();
              if (isDownloadCsv && result.errorData) this.downloadCsv(result.errorData, result.filename);
              this.$message.showSuccess(`import_${type}_finished_fail`);
              this.removeImportingCsv();
              if (this.$refs?.table || resetCheckbox) this.$refs.table.resetCheck();
              break;
            case 3:
              this.$message.showError(`import_${type}_fail`);
              this.$refs.pagination.resetPagination();
              if (isDownloadCsv && !cancelType) this.downloadCsv(result.errorData, result.filename);
              this.removeImportingCsv();
              if (this.$refs?.table || resetCheckbox) this.$refs.table.resetCheck();
              break;
            case 4:
              this.$message.showError(`import_${type}_duplicate_fail`);
              this.$refs.pagination.resetPagination();
              this.removeImportingCsv();
              if (this.$refs?.table || resetCheckbox) this.$refs.table.resetCheck();
              break;
            default:
              break;
          }
        } else {
          this.removeImportingCsv();
          if (this.$refs?.table || resetCheckbox) this.$refs.table.resetCheck();
        }
      }, 10000);
    },
    onCsvFilePicked(event, noCountMessage = '', searchCondition = null, isDownloadCsv = true, type = 'csv', cancelType = null) {
      if (!this.isImportDisabled) {
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        if (checkFileSize(file) && checkCsvFile(file)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            const csvResult = e.target.result;
            const detectedEncoding = Encoding.detect(csvResult);
            if (FileConstants.ACCEPTED_CSV_ENCODEING.includes(detectedEncoding)) {
              const formData = new FormData();
              formData.append('input', file);
              if (searchCondition) buildFormData(formData, searchCondition);
              const result = await this.$store.dispatch(this.importCsvAction, formData);
              if (result) {
                if (noCountMessage && !result.data.count) {
                  this.removeImportingCsv();
                  this.$message.showError(noCountMessage);
                } else {
                  this.setImportingCsv(result.data.id);
                  if (noCountMessage) {
                    this.$message.showMessageSuccess(result.data.message);
                  } else {
                    this.$message.showSuccess('importCsvStart');
                  }
                  this.checkImportStatus(result.data.id, type, false, isDownloadCsv, cancelType);
                }
              } else {
                this.removeImportingCsv();
              }
            } else {
              this.$message.showError('unacceptedCharsetCsv');
            }
          }
          reader.readAsBinaryString(file);
        }
      } else {
        this.$message.showError('importing_csv');
      }
      this.removeCsvFile();
    },
    setImportingCsv(id) {
      this.isImportDisabled = true;
      const importingAttendStore = JSON.parse(localStorage.getItem(this.importingItem)) || {};
      importingAttendStore[this.importPath] = id;
      localStorage.setItem(this.importingItem, JSON.stringify(importingAttendStore));
    },
    removeImportingCsv() {
      this.isImportDisabled = false;
      const importingAttendStore = JSON.parse(localStorage.getItem(this.importingItem)) || {};
      delete importingAttendStore[this.importPath];
      localStorage.setItem(this.importingItem, JSON.stringify(importingAttendStore));
    },
  },
  mounted() {
    const importingId = (JSON.parse(localStorage.getItem(this.importingItem)) || {})[this.importPath];
    if (importingId) {
      this.isImportDisabled = true;
      this.checkImportStatus(importingId, importingId.currencyType);
    }
  }
};
